'use strict';

scriptSync("utils", () => {
    addEventAll("button.scroll-logos-list__button.scroll-logos-list__button--left",
        ((e) => {
            scrollLeft("#" + e.currentTarget.dataset.scrollLogosListId);
        }), "click", false
    );

    addEventAll("button.scroll-logos-list__button.scroll-logos-list__button--right",
        ((e) => {
            scrollRight("#" + e.currentTarget.dataset.scrollLogosListId);
        }), "click", false
    );
});

function showScrollButtons(clientElem) {
    if (clientElem.scrollWidth > clientElem.clientWidth) {
        document.querySelectorAll("[data-scroll-logos-list-id=" + clientElem.id + "]")
            .forEach((b) => b.classList.remove("scroll-logos-list__button--hidden"));
        clientElem.setAttribute("tabindex", "0");
    } else {
        document.querySelectorAll("[data-scroll-logos-list-id=" + clientElem.id + "]")
            .forEach((b) => b.classList.add("scroll-logos-list__button--hidden"));
        clientElem.removeAttribute("tabindex", "0");
    }
}

try {
    const resizeClientsObserver = new ResizeObserver(
        (entries) => {
            for (let entry of entries) {
                showScrollButtons(entry.target);
            }
        });

    Array.from(document.getElementsByClassName("scroll-logos-list"))
        .forEach((e) => {
            resizeClientsObserver.observe(e);
            showScrollButtons(e);
        });
} catch (error) {
    console.error("ResizeObserver not supported");
    console.error(error);
}